/*******************************
        User Overrides
*******************************/

.ui.grid .ui.menu,
.ui.grid .ui.tab {
  margin-bottom: 24px;
  border-width: 0;
  border-shadow:  none;
  box-shadow: 0px 16px 24px #ededed;
}

.ui.grid .ui.menu {
  position: sticky;
  top: 10px;
}

.ui.grid .ui.menu .item {
  color: #717171;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ui.grid .ui.menu .item i {
  margin-top: -6px;
  margin-right: 12px;
}

.ui.grid .ui.tab {
  padding: 20px 30px;
}

@media (max-width: 929px) {
  .ui.grid > .four.wide.column,
  .ui.grid > .twelve.wide.column {
    width: 100% !important;
  }

  .ui.grid .ui.menu {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
  }

  .ui.grid .ui.menu .item {
    flex: 1;
    margin-bottom: 0;
    margin-right: 7px;
  }
}
