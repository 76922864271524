/*******************************
         Site Overrides
*******************************/

.text-center {
  text-align: center !important;
}

.d-block {
  display: block !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

[class*=col-] {
  padding-right: 15px;
  padding-left: 15px;
}

.col-sm-1 {
  @media (min-width: 576px) {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
}

.col-sm-2 {
  @media (min-width: 576px) {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

.col-sm-3 {
  @media (min-width: 576px) {
    flex: 0 0 auto;
    width: 25%;
  }
}

.col-sm-4 {
  @media (min-width: 576px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.col-sm-6 {
  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col-sm-12 {
  @media (min-width: 576px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-md-3 {
  @media (min-width: 768px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.col-md-8 {
  @media (min-width: 768px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

.col-md-9 {
  @media (min-width: 768px) {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

fieldset, ol, ul {
  margin: 0;
  padding: 0;
}

ol, ul {
  list-style: none;
}

hr {
  margin: 0;
  border: 0;
  border-top: 1px solid rgba(0,0,0,.1);
}

.form-group {
  margin-bottom: 1rem;
}

label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 700;
}

.custom-control {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.custom-control-input {
  width: 1.5rem;
  height: 1.25rem;
  margin-right: 10px;
}

.custom-control-input[type="checkbox"] {
  flex-grow: 0;
}

.custom-checkbox > .custom-control-label {
  margin-bottom: 0 !important;
}
