/*******************************
        User Overrides
*******************************/

.ui.fluid.dropdown > .dropdown.icon {
  display: flex;
  align-items: center;
  height: 100%;
}

.ui.dropdown:not(.button) > .default.text,
.ui.default.dropdown:not(.button) > .text {
  color: #97a1a8;
}

.ui.dropdown > div[role="listbox"] {
  width: 100%;
}

.ui.dropdown {
  height: auto;
}
