/*******************************
         Site Overrides
*******************************/

.ui.search > .results.transition > .category > .name {
  color: #717171;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
