/*******************************
         Site Overrides
*******************************/

.ui.secondary.buttons .button, .ui.secondary.button {
  background-color: #f90f90;

  &:hover,
  &:focus {
    background-color: #f90f90;
    opacity: 0.7;
  }
}
