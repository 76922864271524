/*******************************
         Site Overrides
*******************************/

.ui.modal > .actions {
  display: flex;
  justify-content: center;
}

.ui.modal > .actions > .ui.button.primary {
  order: 1;
  margin-left: 0.25em;
}

.ui.modal > .actions > .ui.button:not(.primary) {
  order: 2;
}

.ui.modal.basic > .header {
  border-bottom: none;
}

.ui.modal.fullscreen > .content.scrolling {
  min-height: calc(100vh - 10em);
  overflow-x: hidden;
  position: relative;
}

.ui.modal.fullscreen > .content.scrolling::-webkit-scrollbar {
  width: 15px;
}

.scrolling.dimmable > .dimmer {
  justify-content: center;
}
